export const About = () => {
  return (
    <main>
      <article id="about">
        <h1>About</h1>
        <p>Out of personal frustration of visualizing complicated LaTeX tables generated by code, and a passion for web development, LaTeX Table Viewer came to life!</p>
        <p>Simply copy and paste your LaTeX table code into the code editor and compile your table. All compilation happens on your computer, no data/information is collected. If you need additional packages, view the full source code and add it where appropriate.</p>
        <br />
        <h2>Credits</h2>
        <p>This project is powered by <a href="https://github.com/SwiftLaTeX/SwiftLaTeX">SwiftLaTeX</a> and their efforts of creating a Web Assembly binary for TeXLive. I tweaked their code slightly to make it work nice with Webpack and it is freely available on <a href="https://github.com/adamkaplan0/swiftlatex-engine/tree/web_worker_compatible">GitHub</a> alongside the <a href="https://github.com/adamkaplan0/latextableviewer/">LaTeX Table Viewer</a>.</p>
      </article>
    </main>
  );
};